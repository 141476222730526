import * as R from 'ramda'
import React, { useEffect, useState, useRef } from 'react'
import Papa from 'papaparse'
import PropTypes from 'prop-types'

import { gql, useMutation, useQuery } from '@apollo/client'

import { EnsureRole } from '../../components/admin/ensure-role'
import { Table } from '../../components/table'

import * as styles from './upload-price-list.module.css'

const QUERY_PRICE_LIST = gql`
  query viewPriceList {
    priceLists {
      nodes {
        gold
        nodeId
        platinum
        retail
        sku
      }
    }
  }
`

const UPLOAD_PRICE_LIST = gql`
  mutation uploadPriceList($input: PriceListUploadInput!) {
    uploadPriceList(input: $input) {
      message
    }
  }
`

function Success() {
  return <div>Upload successful!</div>
}

function Error() {
  return <div>Upload failed!</div>
}

const FileUpload = ({ onUpload, ...rest }) => {
  const input = useRef()

  const handleUpload = async (e) => {
    e.preventDefault()

    const file = input.current.files[0]

    const text = await file.text()
    const { data: csvData } = Papa.parse(text, { header: true })

    const transform = R.map((x) => {
      return {
        sku: x.SKU || 'unknown',
        retail: x.Retail,
        gold: x.Gold,
        platinum: x.Platinum,
      }
    })

    onUpload(transform(csvData))
  }

  return (
    <form
      {...rest}
      encType="multipart/form-data"
      action="/upload"
      method="post"
    >
      <label htmlFor="file">Price list:</label>
      <input ref={input} id="file" type="file" />{' '}
      <div className={styles.actionBar}>
        <button type="submit" onClick={handleUpload}>
          Upload
        </button>
      </div>
    </form>
  )
}

FileUpload.propTypes = {
  onUpload: PropTypes.func,
}

export default function UploadPriceListPage() {
  const [uploadPriceList, { loading: uploading }] = useMutation(
    UPLOAD_PRICE_LIST,
    {
      refetchQueries: { queries: [QUERY_PRICE_LIST] },
    },
  )

  const {
    data,
    loading,
    error,
    refetch: refetchPriceList,
  } = useQuery(QUERY_PRICE_LIST)

  const [showSuccess, setShowSuccess] = useState(false)
  const [showError, setShowError] = useState(false)

  useEffect(() => {
    const resetStatusMessage = () => {
      setTimeout(() => {
        setShowSuccess(false)
        setShowError(false)
      }, 2000)
    }

    resetStatusMessage()
  })

  const getPriceList = R.compose(
    //
    R.propOr([], 'nodes'),
    R.propOr({}, 'priceLists'),
  )

  const priceList = getPriceList(data)

  const handleUpload = async (data) => {
    setShowSuccess(false)
    setShowError(false)

    const batchUploads = R.compose(
      R.map((part) => {
        return uploadPriceList({
          variables: { input: { priceList: part } },
        })
      }),
      R.splitEvery(300),
    )

    const promises = batchUploads(data)

    try {
      await Promise.all(promises)

      setShowSuccess(true)
      refetchPriceList()
    } catch (err) {
      setShowError(true)

      console.error(err)
    }
  }

  return (
    <EnsureRole
      userRole="admin"
      protectedContent={() => (
        <>
          {error ? (
            <div>Error</div>
          ) : loading ? (
            <div>loading</div>
          ) : (
            <>
              <div>
                <h2>Upload Price List</h2>
                <FileUpload
                  className={styles.fileUpload}
                  onUpload={handleUpload}
                />
                {uploading && <div>Uploading...</div>}
                {showSuccess && <Success />}
                {showError && <Error />}
              </div>
              <h1>Price List</h1>
              <Table>
                <Table.Head>
                  <Table.Row>
                    <Table.Header>SKU</Table.Header>
                    <Table.Header>Retail</Table.Header>
                    <Table.Header>Gold</Table.Header>
                    <Table.Header>Platinum</Table.Header>
                  </Table.Row>
                </Table.Head>

                <Table.Body>
                  {priceList.map((item) => (
                    <Table.Row key={item.sku}>
                      <Table.Cell>{item.sku}</Table.Cell>
                      <Table.Cell>{item.retail}</Table.Cell>
                      <Table.Cell>{item.gold}</Table.Cell>
                      <Table.Cell>{item.platinum}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </>
          )}
        </>
      )}
    />
  )
}
